@use '@angular/material' as mat;
// TODO it's causing error during deploy
// @import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

@include mat.core();

// $designer-typography: mat-typography-config(
//   // $button: mat-typography-level(14px, 36px, 500)
// );
$harvester-primary: mat.define-palette(mat.$blue-palette, A200, A100, A400);
$harvester-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$harvester-warn: mat.define-palette(mat.$pink-palette);

$harvester-theme: mat.define-light-theme(
  (
    color: (
      primary: $harvester-primary,
      accent: $harvester-accent,
      warn: $harvester-warn
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

@include mat.all-component-themes($harvester-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
}

.mat-expansion-panel {
  border-radius: 0 !important;
}

.mat-form-field-appearance-standard {
  &.mat-form-field-has-label {
    .mat-select {
      &:not(.mat-select-empty) {
        .mat-select-arrow-wrapper {
          transform: none !important;
        }
      }
    }
  }

  .mat-form-field-flex {
    padding-top: 0.3em !important;
  }
}

.mat-option {
  small {
    display: inline-block;
    margin-left: 16px;
    opacity: 0.64;
  }
}

.material-icons {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

text {
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.node rect {
  stroke: #333;
  fill: #fff;
  stroke-width: 1px;
}

.arrowhead {
  stroke: #999;
  fill: #999;
}

.edgePath path {
  stroke: #999;
  stroke-width: 2px;
}

.cluster .label text {
  font-size: 12px;
  font-weight: 400;
}
.clusters rect {
  fill: transparent;
  stroke: rgba(255, 0, 0, 0.5);
  stroke-width: 2px;
  stroke-dasharray: 3px;
}

.selected rect {
  stroke: rgba(255, 0, 0, 0.5);
  stroke-width: 4px;
}

.mat-tooltip {
  white-space: pre-wrap;
}

.mat-dialog-container {
  position: relative;
}

.drag-handle {
  position: absolute;
  right: 5px;
  top: 5px;
  opacity: 0.2;
  cursor: all-scroll;
}

.footnote-reference {
  vertical-align: super;
  font-size: smaller;
}

.ce-inline-tool {
  justify-content: center;
}

.mat-icon {
  &.hidden {
    opacity: 0;
  }
}

.mat-simple-snackbar span {
  overflow: auto;
}
